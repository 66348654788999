import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import posthog from 'posthog-js';
import {
	initializeAuth0Client,
	handleCallback,
	setRedirectUri,
	refreshToken,
	isTokenExpiringSoon,
	AUTH0_ACCESS_TOKEN_COOKIE
} from '$lib/stores/auth';
import { getCookie } from '$lib/utils/Cookies';
import type { HandleClientError } from '@sveltejs/kit';

if (env.PUBLIC_SENTRY_ENV_TAG === 'prod') {
	posthog.init('phc_NMcUhSsmev8Vb4tTXTkFE6hVG7hfmfjK016VDdm6w1N', {
		opt_in_site_apps: true,
		api_host: 'https://ph.kontali.com',
		ui_host: 'https://us.posthog.com'
	});
}

let isRefreshingToken = false;
const pendingRequests: (() => void)[] = [];

export const handleFetch = async ({
	request,
	fetch
}: {
	request: Request;
	fetch: typeof window.fetch;
}) => {
	const isApiRequest = request.url.startsWith(env.PUBLIC_API_URL);

	if (!isApiRequest) {
		return fetch(request);
	}

	if (isTokenExpiringSoon() && !isRefreshingToken) {
		try {
			isRefreshingToken = true;
			await refreshToken();
		} catch (error) {
			console.error('Failed to refresh token:', error);
		} finally {
			isRefreshingToken = false;

			while (pendingRequests.length > 0) {
				const callback = pendingRequests.shift();
				if (callback) callback();
			}
		}
	}

	const token = getCookie(AUTH0_ACCESS_TOKEN_COOKIE);
	if (token) {
		const headers = new Headers(request.headers);
		headers.set('Authorization', `Bearer ${token}`);
		request = new Request(request, { headers });
	}

	try {
		const response = await fetch(request);

		if (response.status === 401 && !isRefreshingToken) {
			isRefreshingToken = true;

			try {
				const newToken = await refreshToken();

				if (newToken) {
					const newHeaders = new Headers(request.headers);
					newHeaders.set('Authorization', `Bearer ${newToken}`);
					const newRequest = new Request(request, { headers: newHeaders });

					return fetch(newRequest);
				}
			} catch (refreshError) {
				console.error('Failed to refresh token after 401:', refreshError);
			} finally {
				isRefreshingToken = false;

				while (pendingRequests.length > 0) {
					const callback = pendingRequests.shift();
					if (callback) callback();
				}
			}
		}

		return response;
	} catch (error) {
		console.error('API request failed:', error);
		throw error;
	}
};

function setupErrorHandling() {
	if (window.location.hostname !== 'localhost' && window.location.port != '5173') {
		const integrations: any[] = [
			new Sentry.Replay({
				maskAllInputs: false,
				maskAllText: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [window.location.origin, 'cdn.jifo.co', 'infogram.com']
			})
		];

		if (env.PUBLIC_SENTRY_ENV_TAG === 'prod') {
			integrations.push(new posthog.SentryIntegration(posthog, 'kontali', 4505277668720640));
		}
	}
	return Sentry.handleErrorWithSentry();
}

export const handleError: HandleClientError = ({ error, event }) => {
	console.error('Client error:', error);

	if (
		(error instanceof Error && error.message.includes('401')) ||
		error.message.includes('403') ||
		error.message.includes('Unauthorized') ||
		error.message.includes('Forbidden')
	) {
		if (!isRefreshingToken) {
			refreshToken().catch((e) => {
				console.error('Failed to refresh token after error:', e);
			});
		}
	}

	return setupErrorHandling()({ error, event });
};

async function initialize() {
	try {
		if (typeof window === 'undefined') {
			return;
		}

		const searchParams = window.location.search
			? new URLSearchParams(window.location.search)
			: new URLSearchParams();
		setRedirectUri(searchParams);

		await initializeAuth0Client();

		if (window.location.search && window.location.search.includes('code=')) {
			await handleCallback();
		}
	} catch (error) {
		console.error('Error during auth initialization:', error);
	}
}

function safeInitialize() {
	if (typeof window === 'undefined' || typeof document === 'undefined') {
		return;
	}

	setTimeout(() => {
		try {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', initialize);
			} else {
				initialize();
			}
		} catch (error) {
			console.error('Error in initialization setup:', error);
		}
	}, 10);
}

safeInitialize();
